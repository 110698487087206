









































































import { Component, Prop, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import Table from "../components/commons/tables/Table.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import { FilterOperatorEnum, TableOptions } from "../types";
import {
  ExportLaborerEventsQuery,
  ExportLaborerEventsQueryVariables,
  Laborer,
  LaborerEventsCustom,
  LaborerEventsFilterEnum,
  LaborerEventTypeEnum,
} from "../types/gqlGeneratedPrivate";
import { TablesNamespaces } from "../store/modules/tables";
import Modal from "../components/commons/Modal.vue";
import { EventBus } from "../enums";
import { eventBus } from "../utils/eventBus";
import LaborerEventDeleteModal from "../components/app/laborerEvents/LaborerEventDeleteModal.vue";
import LaborerEventCreateModal from "../components/app/laborerEvents/LaborerEventCreateModal.vue";
import LaborerEventUpdateModal from "../components/app/laborerEvents/LaborerEventUpdateModal.vue";
import { toLocalDate } from "../filters/datetime";
import { apiClient } from "../utils";
import { api } from "../api";

@Component({
  filters: {
    toLocalDate,
  },
  components: {
    AppLayout,
    Button,
    Modal,
    RoundButton,
    Table,
    TableHeader,
    ActionsWrapper,
    LaborerEventDeleteModal,
    LaborerEventCreateModal,
    LaborerEventUpdateModal,
  },
})
export default class LaborerDetailEventsPage extends Vue {
  private eventName = "";

  @Prop({ type: Object, default: () => ({}) })
  data!: Laborer;

  laborerId: number = parseInt(this.$route.params.id);

  dates = [];

  tableOptions: TableOptions<LaborerEventsCustom, LaborerEventsFilterEnum> = {
    defaultSortBy: ["fromDate"],
    defaultFilter: [
      {
        filter: {
          column: LaborerEventsFilterEnum.Laborer,
          operator: FilterOperatorEnum.Equal,
          values: [this.laborerId.toString()],
        },
      },
    ],
    headers: [
      {
        text: "Událost",
        sortable: true,
        align: "start",
        value: "type",
      },
      {
        text: "Od",
        sortable: true,
        align: "start",
        value: "fromDate",
      },
      {
        text: "Do",
        sortable: true,
        align: "start",
        value: "toDate",
      },
      {
        text: "",
        sortable: true,
        align: "start",
        value: "isHalfDay",
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    namespace: TablesNamespaces.LaborerEventsTable,
  };

  getTypeLabel(type: LaborerEventTypeEnum): string {
    if (type === LaborerEventTypeEnum.Sickness) {
      return "Nemoc";
    } else {
      return "Dovolená";
    }
  }
  getIsHalfDayLabel(isHalfDay: boolean): string {
    return isHalfDay ? "1/2" : "";
  }

  deleteItem(laborerEvent: LaborerEventsCustom): void {
    this.eventName = `${laborerEvent.type} - ${laborerEvent.fromDate}-${laborerEvent.toDate}`;

    eventBus.$emit(EventBus.LaborerEventDeleteModal, laborerEvent);
  }

  addItem(): void {
    eventBus.$emit(EventBus.LaborerEventCreateModal, this.laborerId);
  }

  updateItem(laborerEvent: LaborerEventsCustom): void {
    eventBus.$emit(EventBus.LaborerEventUpdateModal, laborerEvent);
  }

  async exportEvents(dates: string[]): Promise<void> {
    const [from, to] = dates;
    if (dates) {
      const { data } = await apiClient.callGraphqlPrivate<
        ExportLaborerEventsQuery,
        ExportLaborerEventsQueryVariables
      >({
        ...api.laborerEvents.export,
        variables: {
          input: {
            laborerId: this.laborerId,
            from,
            to,
          },
        },
      });
      if (data?.exportLaborerEvents) {
        const { firstname, surname } = this.data;
        const filename = `export_${firstname}_${surname}_${from}_${to}.csv`;
        this.download(filename, data?.exportLaborerEvents);
      }
    }
  }

  download(filename: string, text: string) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
