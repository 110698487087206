



















import Button from "../../commons/inputsAndControls/Button.vue";
import LaborerEventForm from "./LaborerEventForm.vue";
import Modal from "../../commons/Modal.vue";
import { Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "../../../enums";
import {
  LaborerEventTypeEnum,
  CreateLaborerEventMutation,
  CreateLaborerEventInput,
  CreateLaborerEventMutationVariables,
  UpdateLaborerEventInput,
} from "../../../types/gqlGeneratedPrivate";
import { TableRefreshPayload } from "../../../types";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { alerts } from "../../../utils";
import { api } from "../../../api";
import { apiClient } from "../../../utils/apiClient";
import { eventBus } from "../../../utils/eventBus";

const emptyFormData: UpdateLaborerEventInput = {
  ids: [],
  laborerId: -1,
  fromDate: null,
  isHalfDay: false,
  toDate: null,
  type: LaborerEventTypeEnum.Vacation,
};

@Component({
  components: {
    Button,
    Modal,
    LaborerEventForm,
  },
})
export default class LaborerEventCreateModal extends Vue {
  isOpen = false;
  isSending = false;

  formData: UpdateLaborerEventInput = {
    ...emptyFormData,
  };

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.LaborerEventsTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  laborerId!: number;

  created(): void {
    eventBus.$on(EventBus.LaborerEventCreateModal, (laborerId: number) => {
      this.laborerId = laborerId;
      this.initForm();
      this.isOpen = true;
    });
  }

  async onSubmit(): Promise<void> {
    const valid = await (this.$refs.form as any).validate();
    if (valid) {
      this.sendForm(this.formData);
    }
  }

  async sendForm(formData: UpdateLaborerEventInput): Promise<void> {
    const transformedInput: CreateLaborerEventInput = {
      laborerId: formData.laborerId,
      type: formData.type,
      isHalfDay: formData.isHalfDay,
      from: formData.fromDate,
      to: formData.isHalfDay ? formData.fromDate : formData.toDate,
    };
    this.isSending = true;
    const result = await apiClient.callGraphqlPrivate<
      CreateLaborerEventMutation,
      CreateLaborerEventMutationVariables
    >({
      ...api.laborerEvents.createLaborerEvent,
      variables: {
        input: transformedInput,
      },
    });
    if (result.data) {
      alerts.addSuccessAlert("CREATE_LABORER_EVENT", "Uloženo");
      this.refreshTable({
        namespace: TablesNamespaces.LaborerEventsTable,
      });
    }
    this.initForm();
    this.isOpen = false;
  }

  initForm(): void {
    this.formData = {
      ...emptyFormData,
      laborerId: this.laborerId,
    };
    this.isSending = false;
    this.$nextTick(() => {
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    });
  }

  cancel(): void {
    this.isOpen = false;
    this.initForm();
  }
}
