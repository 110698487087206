







































import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import SelectBox from "../../commons/inputsAndControls/SelectBox.vue";
import DateField from "../../commons/inputsAndControls/DateField.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  LaborerEventTypeEnum,
  UpdateLaborerEventInput,
} from "../../../types/gqlGeneratedPrivate";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    Button,
    DateField,
    SelectBox,
    TextField,
    ValidationObserver,
  },
})
export default class LaborerEventForm extends Vue {
  types = [
    {
      value: LaborerEventTypeEnum.Vacation,
      text: "Dovolená",
    },
    {
      value: LaborerEventTypeEnum.Sickness,
      text: "Nemoc",
    },
  ];

  @Prop({ type: Object, required: true })
  value!: UpdateLaborerEventInput;

  async validate(): Promise<boolean> {
    return (this.$refs.observer as any).validate();
  }

  reset(): void {
    (this.$refs.observer as any).reset();
  }
}
