



















import Button from "../../commons/inputsAndControls/Button.vue";
import LaborerEventForm from "./LaborerEventForm.vue";
import Modal from "../../commons/Modal.vue";
import { Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "../../../enums";
import {
  LaborerEventTypeEnum,
  UpdateLaborerEventInput,
  UpdateLaborerEventMutation,
  UpdateLaborerEventMutationVariables,
} from "../../../types/gqlGeneratedPrivate";
import { TableRefreshPayload } from "../../../types";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { alerts } from "../../../utils";
import { api } from "../../../api";
import { apiClient } from "../../../utils/apiClient";
import { eventBus } from "../../../utils/eventBus";

const emptyFormData: UpdateLaborerEventInput = {
  fromDate: null,
  ids: [],
  isHalfDay: false,
  laborerId: 0,
  toDate: null,
  type: LaborerEventTypeEnum.Vacation,
};

@Component({
  components: {
    Button,
    Modal,
    LaborerEventForm,
  },
})
export default class LaborerEventUpdateModal extends Vue {
  isOpen = false;
  isSending = false;

  laborerEventId = 0;

  formData: UpdateLaborerEventInput = {
    ...emptyFormData,
  };

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.LaborerEventsTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  created(): void {
    eventBus.$on(
      EventBus.LaborerEventUpdateModal,
      (payload: UpdateLaborerEventInput) => {
        this.formData = {
          ids: payload.ids,
          fromDate: payload.fromDate,
          isHalfDay: payload.isHalfDay,
          laborerId: payload.laborerId,
          toDate: payload.toDate,
          type: payload.type,
        };
        this.isOpen = true;
        this.initForm();
      }
    );
  }

  async onSubmit(): Promise<void> {
    const valid = await (this.$refs.form as any).validate();
    if (valid) {
      this.sendForm(this.formData);
    }
  }

  async sendForm(formData: UpdateLaborerEventInput): Promise<void> {
    this.isSending = true;
    if (formData.isHalfDay) {
      formData = {
        ...formData,
        toDate: formData.fromDate,
      };
    }
    const result = await apiClient.callGraphqlPrivate<
      UpdateLaborerEventMutation,
      UpdateLaborerEventMutationVariables
    >({
      ...api.laborerEvents.updateLaborerEvent,
      variables: {
        input: {
          ...formData,
        },
      },
    });
    if (result.data) {
      alerts.addSuccessAlert("UPDATE_LABORER_EVENT", "Uloženo");
      this.refreshTable({
        namespace: TablesNamespaces.LaborerEventsTable,
      });
    }
    this.isOpen = false;
    this.isSending = false;
  }

  initForm(): void {
    this.isSending = false;
    this.$nextTick(() => {
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    });
  }

  cancel(): void {
    this.isOpen = false;
    this.initForm();
  }
}
